<template>
  <v-container>
    <h1>Help</h1>
    <v-row>
      <v-col md="2">
        <v-tabs vertical v-model="tab">
          <v-tab>Welcome</v-tab>
          <v-tab>Quick Start</v-tab>
          <v-tab>Schedules</v-tab>
        </v-tabs>
      </v-col>
      <v-col md="10">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-title>
                Welcome to TheFlightSimKit!
              </v-card-title>
              <v-card-text>
                Our website and client are continuing to undergo development and so we appreciate you using our system!<br>
                If you have any issues or ideas for us don't hesitate to contact us and we can look into implementing them.<br>
                These pages are intended to answer any questions you may have about our tool, however, if you have any additional questions please contact us.
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-title>
                Quick Start
              </v-card-title>
              <v-card-text>
                <p>At the moment and for the foreseeable our platform is only available for Windows and is intended for Microsoft Flight Simulator 2020.</p>
                <p>Firstly create an account on our website. If you haven't done so already this is simple! Click 'Register' above!</p>

                <h3>Setup client</h3>
                <div style="margin-top: 16px; margin-bottom: 16px">
                  <ol>
                    <li><a :href="this.$baseURL + '/downloads/TheFlightSimKitClient.exe'">Download</a> the client.</li>
                    <li>Head over to account settings and click 'Generate Token' this will be used to login to your account with the client.</li>
                    <li>Start the client.</li>
                    <li>Login to the client. To do this you'll use your username and the token we just generated on the account page.</li>
                  </ol>
                </div>
                <p>
                  Once started the client will run in the system tray and will wait for the game to start. Once started
                  it will remain running there. <br><br>
                  Note that by default discord rich presence will be enabled, this can be turned off through the menu in
                  the system tray.
                </p>

                <h3>Create an Aircraft</h3>
                <p>Creating and managing an aircraft can be done on the 'Fleet' page.<br>
                An aircraft requires three things.</p>
                <div style="margin-top: 16px; margin-bottom: 16px">
                  <ol>
                    <li>An airline. This can be chosen from our drop-down or custom. Custom items can be added on the 'Airframes & Custom Airlines' tab.</li>
                    <li>An airframe. These are SimBrief airframes. This can be chosen from our drop-down or custom. Custom items can be added on the 'Airframes & Custom Airlines' tab.</li>
                    <li>A registration. This can be anything you want: valid or invalid.</li>
                  </ol>
                </div>

                <h3>Starting a Flight</h3>
                <p>
                  <b>Before starting a flight you must first create an aircraft</b><br>
                  There are four ways of starting a flight. Flights can be started under 'Flight Planning' on the 'flights' page.
                </p>
                <div style="margin-top: 16px; margin-bottom: 16px">
                  <ol>
                    <li>Creating one with SimBrief. This will require a SimBrief login. This can be done using the left-hand side of the page.</li>
                    <li>Loading an existing one with SimBrief. If you want to use this option fill in your SimBrief username in the account page, once done this can be done by just clicking 'Load From SimBrief'.</li>
                    <li>Load in a Microsoft Flight Simulator 2020 flight plan (.PLN file). This can be done by just uploading the plan and clicking 'Load MSFS Plan'.</li>
                    <li>Start a 'FreeFlight'. Note this will be tracked but does not provide any route / origin / destination data. This can be done by clicking 'Start FreeFlight'</li>
                  </ol>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-title>
                Flight Schedules
              </v-card-title>
              <v-card-text>
                <p>
                  Flight schedules are a feature which allows users to plan flights ahead of time and save them so that they can easily be flown in the future. <br>
                  A flight schedule stores an airline, flight number, aircraft (from your fleet), origin and destination. These schedules can be flown and a flight plan generated simply by clicking "dispatch" from the schedule page. <br>
                  There are three ways to 'schedule' a flight:
                </p>
                <div style="margin-top: 16px; margin-bottom: 16px">
                  <ol>
                    <li>Manually add one by clicking 'Add Flight' from the 'Current Schedule' tab. </li>
                    <li>Add a 'real' flight by heading to the 'Real Schedules' tab and clicking 'schedule' on the flight you want to schedule. </li>
                    <li>Add a 'real' flight by heading to the 'Airline Routes' tab and clicking 'schedule' on the flight you want to schedule.</li>
                  </ol>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Help",
  data: function(){
    return {
      tab: null,
    }
  }
}
</script>

<style scoped>

</style>
